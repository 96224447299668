var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-header'),_c('div',{staticClass:"finishingsDetail"},[(_vm.workingObject)?_c('boxes',{attrs:{"options":{
        bg: 'gray',
        title: 'label.finishings',
        type:'finishingsBox',
        businesskey:'finishings',
        modal:'fiModal',
        data: _vm.finishings,
        PrID: _vm.workingObject.PrID,
        MpGuid: _vm.workingObject.MpGuid,
        buttons: ['addButton'],
        enableBoxLink: true
      }}}):_vm._e(),(_vm.workingObject)?_c('editors',{attrs:{"screen":"frmFinishingDetails"}}):_vm._e(),_c('nv-loading',{attrs:{"show":_vm.loading}}),_c('ExportProjectPopup',{attrs:{"exportPopupOpen":_vm.exportPopupOpen},on:{"closeButtonClicked":_vm.toggleExportPopup}})],1),_c('app-footer',{attrs:{"saveComponentDataFunction":_vm.save}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }