<template>
  <div>
    <app-header/>
    <div class="finishingsDetail">
      <boxes
        v-if="workingObject"
        :options="{
          bg: 'gray',
          title: 'label.finishings',
          type:'finishingsBox',
          businesskey:'finishings',
          modal:'fiModal',
          data: finishings,
          PrID: workingObject.PrID,
          MpGuid: workingObject.MpGuid,
          buttons: ['addButton'],
          enableBoxLink: true
        }">
      </boxes>
      <editors v-if="workingObject" screen='frmFinishingDetails'></editors>
      <nv-loading :show="loading"></nv-loading>

      <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>
    </div>
    <app-footer :saveComponentDataFunction="save" />
  </div>
</template>

<script>
import $ from 'jquery'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import nvLoading from '@/components/_shared/loading.vue'
import Editors from '@/components/editors.vue'
import _ from 'lodash'
import boxes from '@/components/boxes.vue'
import gapsRule from '@/utils/gapsRule'
import { FINISHING_TYPE } from '@/vuex/constants'
import workingObjectUtils from '@/utils/workingObjectUtils'
import { FinishingsService } from '@/services/DataWS/finishingsService'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

const finishingsService = new FinishingsService()
var self

/**
   * The details of one specific finishing.
   */
export default {
  name: 'finishings',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    boxes,
    ExportProjectPopup
  },
  data () {
    return {
      loading: false,
      PrID: null,
      MpGuid: null,
      exportPopupOpen: false
    }
  },
  computed: {
    finishings: {
      get () {
        return this.$store.state.finishings
      },
      set (value) {
        this.$store.commit('setFinishings', value)
      }
    },
    workingObject: {
      get () {
        return this.$store.state.workingObject
      },
      set (value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get () {
        return this.$store.state.workingObjectType
      },
      set (value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get () {
        return this.$store.state.workingObjectIndex
      },
      set (value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    },
    currentMeasurementPoint: { // uses mpGuid and measurementpoints to get the current measurementpoint
      get () {
        if (this.measurementPoints) {
          if (this.MpGuid) {
            return this.measurementPoints.find(m => m.MpGuid === this.MpGuid)
          }
        }
        return null
      }
    }
  },
  watch: {
    // on route change, this is NOT called for fresh loads. fresh loads are handled in created()
    '$route': {
      handler: function () {
        this.format()
        // update active finishing
        this.setActiveFinishing()
      },
      deep: true
    }
  },
  created () {
    self = this

    this.MpGuid = this.$route.params.measurementPointId
    this.PrID = this.$route.params.projectId

    let tasks = []

    // if no finishings are loaded, then load
    if (!(_.isArray(this.finishings) && this.finishings.length)) {
      tasks.push(finishingsService.getFinishings(this.PrID, this.MpGuid)
        .then((res) => {
          // show loading indicator
          self.loading = true

          gapsRule.getSortData(res.tblFinishings, 'FiFrom', 'FiTo')
          self.finishings = res.tblFinishings

          // hide loading indicator
          self.loading = false
        })
        .catch(err => {
          self.loading = false
          console.error(err)
        })
      )
    }

    window.Promise.all(tasks)
      .then(() => {
        // format raw finishings and set active finishing
        this.format()
        this.setActiveFinishing()
      })
  },
  methods: {
    format () {
      gapsRule.getSortData(this.finishings, 'FiFrom', 'FiTo')
    },
    setActiveFinishing () {
      this.workingObjectIndex = _.findIndex(this.finishings, { FiID: this.$route.params.FiID })

      this.finishings.forEach(ed => (ed.isActive = false))

      this.workingObject = this.finishings[this.workingObjectIndex]
      this.workingObject.isActive = true
      this.workingObjectType = FINISHING_TYPE

      this.setState('FiFrom', 'FiTo')
    },
    setState (from, to) {
      var titleParts = []
      if (this.workingObject['FiTo'] !== undefined) {
        titleParts.push(this.workingObject['FiTo'])
      }
      if (this.workingObject['FiFrom'] !== undefined) {
        titleParts.push(this.workingObject['FiFrom'])
      }
      this.$store.dispatch('setActiveTitle', titleParts.join('-'))
      this.$store.dispatch('setPrevTitle', (this.currentMeasurementPoint || {}).MpName)
    },
    save () {
      this.loading = true
      return workingObjectUtils.pushWorkingObjectToDataService({
        PrID: this.PrID,
        MpGuid: this.MpGuid
      })
        .then(res => {
          self.loading = false
          // update finishings with response from server
          self.finishings = res
          this.format()
          return true
        })
        .catch(err => {
          self.loading = false
          self.handlerError(err)
        })
    },
    delete () {
      let modalDelete
      modalDelete = $('#modalDeleteFinishings')
      modalDelete.modal('toggle')
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}
</script>
<style>
    @media (max-width: 640px) {
        .finishingsDetail .edit-panel .input-group.input-left {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .finishingsDetail .editor-panel {
            width: calc(100vw - 410px);
        }
    }
</style>
